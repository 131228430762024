import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

import SEO from '../components/SEO'
import Feedbacks from '../components/Feedbacks'
import Logo from '../images/logos/contego-logo.png'

const Reviews = () => {
  const goToTop = React.useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className="container">
      <SEO
        title="REVIEWS"
        description="What customers, technicians and nail salon owners think about Contego"
        meta={[
          {
            name: 'keywords',
            content: 'Contégo, spa chair, pedicure spa chair, nail, nail salon, pedicure sanitation'
          }
        ]}
      />

      <div className="row">
        <div className="col py-4">
          <img
            src={Logo}
            width="180px"
            height="auto"
            style={{ display: 'block', margin: '1rem auto' }}
            alt="contego company logo"
          />

          <h4 className="my-auto px-4 text-center">
            CUSTOMERS, TECHNICIANS & SALON OWNERS REVIEWS
          </h4>
        </div>
      </div>

      <Feedbacks />

      <div className="row">
        <div className="col px-0">
          <div className="w-100 d-flex flex-wrap py-2 justify-content-center">
            <a href="https://www.contegospa.com/privacy" className="my-4 mr-4">
              Privacy Policy
            </a>

            <a href="https://www.contegospa.com/digital-catalog/" className="my-4 mr-4">
              Digital Catalog
            </a>
          </div>
        </div>
      </div>

      <Button icon size="big" basic onClick={goToTop} className="rounded-circle back-to-top-btn">
        <Icon name="angle up" />
      </Button>
    </div>
  )
}

export default Reviews
